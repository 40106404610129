import React, { useState } from 'react';
import Title from 'antd/es/typography/Title';
import { extractAllText, extractMetadata, extractPDFsFromZip, getMatches, processPdf } from '../community-hats-js-library/utils/labFunctions';

import { Upload, Button, message, Card, Progress, Spin, Input, Space, Table } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { RefParticipantsData } from '../hooks/DatasetHooks';
import * as libCon from "../community-hats-js-library/Constants"
import { isNullOrUndefined, isNullOrUndefinedOrEmpty } from '../community-hats-js-library/utils/generalFunctions';
import { exportToExcel, parseExcelFile } from '../community-hats-js-library/utils/ExcelFunctions';
import { format } from 'date-fns';
import { uploadPDFToBucket } from '../community-hats-js-library/utils/AWSFunctions';
import { formatExport, formatToIndiaTimeReadable } from '../community-hats-js-library/utils/dateFunctions';
import { LoadingParticipants } from '../components/LoadingDatasets';



const GENERAL_COLUMNS = [
    {
        title: libCon.ATF_CASE_ID,
        dataIndex: libCon.ATF_CASE_ID,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_SAMPLE_DATE,
        dataIndex: libCon.ATF_SAMPLE_DATE,
        align: 'center',
        width: 100,
        render: (val) => formatToIndiaTimeReadable(val, true)

    },
    {
        title: libCon.ATF_NAME,
        dataIndex: libCon.ATF_NAME,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_AGE,
        dataIndex: libCon.ATF_AGE,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_PHONE_NUMBER,
        dataIndex: libCon.ATF_PHONE_NUMBER,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_TARGET_COMPLETE_NAME,
        dataIndex: libCon.ATF_TARGET_COMPLETE_NAME,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_NAME_SCORE,
        dataIndex: libCon.ATF_NAME_SCORE,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_NAME_SCORE_SECOND_BEST,
        dataIndex: libCon.ATF_NAME_SCORE_SECOND_BEST,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_TARGET_AGE,
        dataIndex: libCon.ATF_TARGET_AGE,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_AGE_DIFF,
        dataIndex: libCon.ATF_AGE_DIFF,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_TARGET_PHONE_NUMBER,
        dataIndex: libCon.ATF_TARGET_PHONE_NUMBER,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_PHONE_NUMBER_MATCH,
        dataIndex: libCon.ATF_PHONE_NUMBER_MATCH,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_TARGET_SEWA_ID,
        dataIndex: libCon.ATF_TARGET_SEWA_ID,
        align: 'center',
        width: 100,
    }

]

function MatchChecker({ pdfFiles, participants }) {

    const [caseId, caseID] = useState(null)

    const [closestMatches, setClosestMatches] = useState([])


    const showResults = async () => {

        setClosestMatches([])

        let finalCaseId = `${caseId}`

        if (isNullOrUndefined(caseId)) {
            return
        }

        if (isNullOrUndefined(pdfFiles)) {
            return
        }


        if (!(finalCaseId in pdfFiles)) {

            return
        }

        let file = pdfFiles[finalCaseId]

        const text = await extractAllText(new Uint8Array(file))
        let metadata = extractMetadata(text)

        let sortedMatches = getMatches(metadata, participants)

        sortedMatches = sortedMatches.map(ob => { return ({ ...ob, ...metadata }) })

        setClosestMatches(sortedMatches.slice(0, 10))


    }

    return (
        <div className="mainContainer" >
            <Title level={3}>{`Match Checker`}</Title>

            <Space direction="horizontal" >
                <Input placeholder="Case ID" value={caseId} onChange={(e) => caseID(e.target.value)} />
                <Button type="primary" onClick={() => showResults()}>Search</Button>
                <Button type="primary" onClick={() => caseID(null)} danger>Clear</Button>
            </Space>

            <div style={{ marginTop: "15px", marginLeft: "10px", marginRight: "10px" }}>
                {
                    closestMatches.length > 0
                        ? <Table dataSource={closestMatches} columns={GENERAL_COLUMNS} size="small" scroll={{ x: 'max-content', y: window.innerHeight * 0.65 }} />
                        : <div></div>
                }
            </div>
        </div>
    )

}



function DashboardUploadLabResults() {


    const [participantStatus, participants] = RefParticipantsData()

    const [pdfFiles, setPdfFiles] = useState(null);

    const [resultsFile, setResultsFileFile] = useState(null);

    const [mappingFile, setMappingFile] = useState(null);

    const [isProcessing, setIsProcessing] = useState(false)
    const [processProgress, setProcessProgress] = useState(0)

    const [isUploading, setIsUploading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)



    // Function to handle file processing
    const processResultsFile = async () => {
        if (!resultsFile) {
            message.error("No file selected!");
            return;
        }

        if (participantStatus !== libCon.OK) {
            message.error("Participants not loaded");
            return;
        }

        setIsProcessing(true)


        const fileBytes = await resultsFile.arrayBuffer();

        // Split the PDF
        const pdfArray = await extractPDFsFromZip(fileBytes);
        const texts = await Promise.all(pdfArray.map(async p => await extractAllText(new Uint8Array(p))))
        let metadataArray = texts.map(t => extractMetadata(t))


        // iterates 
        metadataArray = metadataArray.map((metadata, i) => {
            setProcessProgress(100 * i / metadataArray.length)

            if (isNullOrUndefined(metadata[libCon.ATF_NAME]))
                return metadata

            let sortedMatches = getMatches(metadata, participants)


            return { ...metadata, ...sortedMatches[0] }

        })

        setProcessProgress(100)
        setUploadProgress(0)

        // converts to metadata dict
        let metadataDict = {}
        let pdfDicts = {}
        metadataArray.forEach((metadata, i) => {
            metadataDict[metadata[libCon.ATF_CASE_ID]] = metadata
            pdfDicts[metadata[libCon.ATF_CASE_ID]] = pdfArray[i]
        })

        // Creates the excel for download and manual process
        const now = new Date()
        exportToExcel(Object.values(metadataArray), `Lab Results ${format(now, "yyyy-MM-d")}.xlsx`, GENERAL_COLUMNS.map(ob => ob.dataIndex))

        setPdfFiles(pdfDicts);

        setIsProcessing(false)
    };

    const processMappingFile = async () => {


        if (!mappingFile) {
            message.error("No file selected!");
            return;
        }

        setIsUploading(true)


        const metadataArray = await parseExcelFile(mappingFile)
        const processedFiles = await Promise.all(metadataArray.map(async meta => await processPdf(pdfFiles[meta[libCon.ATF_CASE_ID]], meta[libCon.ATF_TARGET_SEWA_ID])))


        let metadata, file, folder, filename
        for (let i = 0; i < metadataArray.length; i++) {
            metadata = metadataArray[i]
            file = processedFiles[i]

            folder = `${libCon.LAB_RESULTS_FOLDER}/${metadata[libCon.ATF_TARGET_SEWA_ID]}`
            filename = `${metadata[libCon.ATF_CASE_ID]}_${formatExport(metadata[libCon.ATF_SAMPLE_DATE])}.pdf`


            await uploadPDFToBucket(folder, filename, file)

            setUploadProgress(100 * i / metadataArray.length)

        }

        setUploadProgress(100)

        message.success(`Done`);

        setIsUploading(false)



    }


    return (
        <div className="mainContainer" >
            <Title level={1}>{`Lab Uploads`}</Title>


            {/* Results File */}

            {
                participantStatus !== libCon.OK
                    ? <LoadingParticipants />
                    : <div className="mainContainer">
                        <Card style={{ width: "40vw" }}
                            styles={{ header: { backgroundColor: "var(--primary-color-3)" } }}
                            type="inner" size="medium" title="Upload Lab Results">


                            <Upload
                                beforeUpload={(uploadedFile) => {
                                    setResultsFileFile(uploadedFile); // Store the uploaded file
                                    message.success(`Lab results uploaded: ${uploadedFile.name}`);
                                    setProcessProgress(0)
                                    setUploadProgress(0)
                                    setMappingFile(null)

                                    return false; // Prevent automatic upload
                                }}
                                showUploadList={false} // Hide default file list
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>

                            {!isNullOrUndefined(resultsFile)
                                ? <p style={{ marginTop: "10px" }}>Selected File: {resultsFile.name}</p>
                                : <div style={{ marginTop: "10px" }}></div>
                            }

                            {/* Process File Button */}
                            {
                                isProcessing
                                    ? <Spin />
                                    : <Button
                                        type="primary"
                                        style={{ marginTop: "10px" }}
                                        onClick={processResultsFile}
                                    >
                                        Process File
                                    </Button>
                            }


                            <Progress percent={processProgress} status={processProgress >= 100 ? "success" : "active"} />

                        </Card>


                        <Card style={{ marginTop: "10px", width: "40vw" }}
                            styles={{ header: { backgroundColor: "var(--primary-color-3)" } }}
                            type="inner" size="medium" title="Upload Mapping File">



                            {/* Mapping File */}
                            <Upload
                                beforeUpload={(uploadedFile) => {
                                    setMappingFile(uploadedFile); // Store the uploaded file
                                    message.success(`Mapping File ${uploadedFile.name}`);
                                    setUploadProgress(0)

                                    return false; // Prevent automatic upload
                                }}
                                showUploadList={false} // Hide default file list
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>

                            {!isNullOrUndefined(mappingFile)
                                ? <p style={{ marginTop: "10px" }}>Selected File: {mappingFile.name}</p>
                                : <div style={{ marginTop: "10px" }}></div>
                            }

                            {/* Process File Button */}

                            {
                                isUploading === true
                                    ? <Spin />
                                    : <Button
                                        type="primary"
                                        style={{ marginTop: "10px" }}
                                        onClick={processMappingFile}
                                    >
                                        Process File
                                    </Button>
                            }


                            <Progress style={{ width: "100%" }} percent={uploadProgress} status='success' />

                        </Card>

                        {
                            isNullOrUndefinedOrEmpty(pdfFiles)
                                ? <div></div>
                                : <MatchChecker pdfFiles={pdfFiles} participants={participants} />

                        }

                    </div>
            }


        </div>
    )
}

export default DashboardUploadLabResults