import { PDFDocument, rgb } from 'pdf-lib';
import * as libCon from '../Constants';
// import { getDocument } from 'pdfjs-dist';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import { isNullOrUndefined } from './generalFunctions';
import { parse } from 'date-fns';
import JSZip from "jszip";
import jaroWinkler from 'jaro-winkler';


export function namesMatchScore(name1, name2) {

    name1 = name1.toLowerCase().replace("ben", "")
    name2 = name2.toLowerCase().replace("ben", "")
    return jaroWinkler(name1, name2);

}

export function getMatches(pdfMetadata, participants) {


    let processedArray = Object.values(participants).map(p => {

        let values = {}

        // General Info
        values[libCon.ATF_TARGET_COMPLETE_NAME] = `${p[libCon.ATF_NAME]} ${p[libCon.ATF_LAST_NAME]}`
        values[libCon.ATF_TARGET_SEWA_ID] = p[libCon.ATF_SEWA_ID]

        // Target Number
        values[libCon.ATF_TARGET_PHONE_NUMBER] = null
        if (libCon.ATF_PHONE_NUMBER in p)
            values[libCon.ATF_TARGET_PHONE_NUMBER] = p[libCon.ATF_PHONE_NUMBER].replace(/[()\-\s]/g, '');

        values[libCon.ATF_PHONE_NUMBER_MATCH] = `${values[libCon.ATF_TARGET_PHONE_NUMBER]}` === `${pdfMetadata[libCon.ATF_PHONE_NUMBER]}` ? libCon.YES : libCon.NO


        // Traget Age
        values[libCon.ATF_TARGET_AGE] = null
        if (libCon.ATF_AGE in p)
            values[libCon.ATF_TARGET_AGE] = p[libCon.ATF_AGE]

        values[libCon.ATF_AGE_DIFF] = isNullOrUndefined(values[libCon.ATF_TARGET_AGE]) ? null : Math.abs(values[libCon.ATF_TARGET_AGE] - pdfMetadata[libCon.ATF_AGE])

        // Score
        let score = namesMatchScore(values[libCon.ATF_TARGET_COMPLETE_NAME], pdfMetadata[libCon.ATF_NAME])
        values[libCon.ATF_NAME_SCORE] = Math.round(score * 100) / 100;

        return values

    })

    processedArray = processedArray.sort((a, b) => b[libCon.ATF_NAME_SCORE] - a[libCon.ATF_NAME_SCORE]);

    // Adds second best score
    processedArray = processedArray.map((p, i) => { return ({ ...p, [libCon.ATF_NAME_SCORE_SECOND_BEST]: i + 1 >= processedArray.length ? null : processedArray[i + 1][libCon.ATF_NAME_SCORE] }) })
    return processedArray
}


export async function extractPDFsFromZip(zipFileBuffer) {
    try {
        const zip = await JSZip.loadAsync(zipFileBuffer); // Load the ZIP file
        const pdfFiles = [];

        // Iterate through all files in the ZIP
        for (const fileName of Object.keys(zip.files)) {
            if (fileName.endsWith(".pdf")) {
                const pdfBlob = await zip.files[fileName].async("arraybuffer"); // Convert to Blob
                pdfFiles.push(new Uint8Array(pdfBlob));
            }
        }

        return pdfFiles; // Returns an array of { name, blob }
    } catch (error) {
        console.error("Error extracting PDFs:", error);
        return [];
    }
}


export async function splitPdf(pdfBytes) {

    const pdfSize = 3

    const originalPdf = await PDFDocument.load(pdfBytes);

    const pdfs = [];

    let bufferPfs = [];

    let newPdfBytes, newPage

    let currentPDF = await PDFDocument.create();

    for (let i = 0; i < originalPdf.getPageCount(); i++) {

        if (i % pdfSize === 0 && bufferPfs.length > 0) {

            newPdfBytes = await currentPDF.save();
            pdfs.push(newPdfBytes); // Each PDF file as Uint8Array

            bufferPfs = []
            currentPDF = await PDFDocument.create();

        }

        [newPage] = await currentPDF.copyPages(originalPdf, [i]);
        currentPDF.addPage(newPage)
        bufferPfs.push(newPage)


    }

    // Final Document
    if (bufferPfs.length > 0) {
        newPdfBytes = await currentPDF.save();
        pdfs.push(newPdfBytes); // Each PDF file as Uint8Array
    }


    return pdfs; // Array of PDFs split by page
}

export async function extractAllText(pdfBytes) {
    try {
        // Load the PDF document
        const pdfDocument = await pdfjsLib.getDocument({ data: pdfBytes }).promise;

        let fullText = [];

        // Loop through each page
        for (let i = 1; i <= pdfDocument.numPages; i++) {
            const page = await pdfDocument.getPage(i);
            const textContent = await page.getTextContent();

            // Extract text from the page
            const pageText = textContent.items
                .map((item) => item.str);

            fullText = fullText.concat(pageText); // Add a newline after each page
        }

        // Cleans
        fullText = fullText.map(p => p.replace(" ", ""))
        fullText = fullText.filter(p => p !== "")



        return fullText;
    } catch (error) {
        console.error('Error extracting text from PDF:', error);
        throw error;
    }
}

export function extractMetadata(textArray) {

    let i = -1;
    let response = {}


    // Name
    let name = null
    i = textArray.indexOf("Name")
    if (i >= 0) {
        // Colon
        if (i + 1 < textArray.length && textArray[i + 1] === ":")
            i += 1

        if (i + 1 < textArray.length)
            name = textArray[i + 1]
    }
    response[libCon.ATF_NAME] = name


    // CaseId
    let caseId = null
    i = textArray.indexOf("CaseID")
    if (i >= 0) {
        // Colon
        if (i + 1 < textArray.length && textArray[i + 1] === ":")
            i += 1

        if (i + 1 < textArray.length)
            caseId = textArray[i + 1]
    }

    response[libCon.ATF_CASE_ID] = caseId


    // Age
    let age = null
    i = textArray.indexOf("Sex/Age")
    if (i >= 0) {
        i += 3
        if (i + 1 < textArray.length) {
            age = textArray[i + 1]
            age = parseInt(age.replace("Years", ''))

            if (isNullOrUndefined(age))
                age = null
        }

    }
    response[libCon.ATF_AGE] = age


    // Phone Number
    let phoneNumber = null
    i = textArray.indexOf("MobileNo.")
    if (i >= 0) {
        // Colon
        if (i + 1 < textArray.length && textArray[i + 1] === ":")
            i += 1

        if (i + 1 < textArray.length) {
            phoneNumber = parseInt(textArray[i + 1])
            if (isNullOrUndefined(phoneNumber))
                phoneNumber = null

        }
    }
    response[libCon.ATF_PHONE_NUMBER] = phoneNumber

    // Sample Date
    let sampleDate = null
    i = textArray.indexOf("RefId2")
    if (i >= 0) {
        // Colon
        if (i + 1 < textArray.length && textArray[i + 1] === ":")
            i += 1

        if (i + 1 < textArray.length) {
            sampleDate = parse(textArray[i + 1], "dd-MMM-yyyyHH:mm", new Date());

        }
    }
    response[libCon.ATF_SAMPLE_DATE] = sampleDate

    return response
}


export async function processPdf(pdfBytes, replacement) {

    // Step 2: Modify the PDF with pdf-lib
    const pdfDoc = await PDFDocument.load(pdfBytes);
    const pages = pdfDoc.getPages();

    pages.forEach((page) => {

        // Name
        // Rectangle
        page.drawRectangle({
            x: 96,
            y: 720,
            width: 174,
            height: 14,
            color: rgb(1, 1, 1), // White color
        });
        // Overlay text
        page.drawText(libCon.REPORT_NAME_MASK, {
            x: 100,
            y: 723,
            size: 13, // Adjust the font size as needed
            color: rgb(1, 1, 1), // Black color
        });

        page.drawText(replacement.toString(), {
            x: 100,
            y: 723,
            size: 13, // Adjust the font size as needed
            color: rgb(0, 0, 0), // Black color
        });

        // Phone Number
        page.drawRectangle({
            x: 502,
            y: 673,
            width: 80,
            height: 14,
            color: rgb(1, 1, 1), // White color
        });
        // Overlay text
        page.drawText(libCon.REPORT_PHONE_MASK, {
            x: 502,
            y: 676,
            size: 13, // Adjust the font size as needed
            color: rgb(1, 1, 1), // Black color
        });

    });

    // Step 3: Save the modified PDF
    const modifiedPdfBytes = await pdfDoc.save();
    return modifiedPdfBytes;
}


