// Dataset Hooks
import { useEffect, useState } from "react"
import { fetchEmailData, fetchHouseData, fetchParticipantData, fetchParticipantPlacementData, fetchPendingIssues, fetchPhoneData, fetchPhonePlacementData, fetchReceivedSensorFiles, fetchSensorData, fetchSensorHousePlacementData, fetchWearableData, fetchWearablePlacementData } from "../store/actions/datasetActions"
import { resetAllDatasets } from "../store/actions/generalStoreActions"
import * as libCon from "../community-hats-js-library/Constants"
import * as dm_fun from "../utils/dataManipulationFunctions"
import { RefStoredValue } from "./GeneralStoreHooks"
import { isNullOrUndefined } from "../community-hats-js-library/utils/generalFunctions"


export const refreshAllDatasets = () => {

    // Resets All Datasets
    resetAllDatasets()

    fetchParticipantData()
    fetchHouseData()
    fetchEmailData()
    fetchParticipantPlacementData()
    fetchSensorData()
    fetchSensorHousePlacementData()
    fetchPhoneData()
    fetchPhonePlacementData()
    fetchWearableData()
    fetchWearablePlacementData()
    fetchReceivedSensorFiles()
    fetchPendingIssues()
}


export const RefParticipantsData = () => {
    const allParticipants = RefStoredValue(libCon.DATASET_PARTICIPANTS)
    const status = RefStoredValue(libCon.STATUS_DATASET_PARTICIPANTS)

    const [participants, setParticipants] = useState(() => null)

    useEffect(() => {

        setParticipants(dm_fun.filterOnlySEWA({ allParticipants }))
    }, [allParticipants])


    return ([status, participants])
}


export const RefActiveParticipantsWithSensorData = () => {
    // Participants
    const allParticipants = RefStoredValue(libCon.DATASET_PARTICIPANTS)
    const statusParticipants = RefStoredValue(libCon.STATUS_DATASET_PARTICIPANTS)

    // Sensors
    const sensors = RefStoredValue(libCon.DATASET_SENSORS)
    const statusSensors = RefStoredValue(libCon.STATUS_DATASET_SENSORS)

    // Sensor Placement
    const sensorPlacements = RefStoredValue(libCon.DATASET_SENSOR_HOUSE_PLACEMENT)
    const statusSensorPlacement = RefStoredValue(libCon.STATUS_DATASET_SENSOR_HOUSE_PLACEMENT)


    const [participants, setParticipants] = useState(() => null)
    const [status, setStatus] = useState(() => libCon.STATUS_NOT_STARTED)


    useEffect(() => {

        if (allParticipants !== null && sensors !== null && sensorPlacements !== null) {
            let newParticipants = dm_fun.filterOnlySEWA({ allParticipants: structuredClone(allParticipants) })
            newParticipants = dm_fun.filterOnlyActive({ participants: newParticipants })
            newParticipants = dm_fun.attachSensorPlacementToParticipants({ participants: newParticipants, sensors: structuredClone(sensors), sensorPlacements: structuredClone(sensorPlacements) })
            newParticipants = dm_fun.computeDaysUntilExpiry({ participants: newParticipants })
            newParticipants = dm_fun.computeDaysSinceLastUpload({ participants: newParticipants })
            setParticipants(newParticipants)

        }

    }, [allParticipants, sensors, sensorPlacements])


    useEffect(() => {

        let arr = [statusParticipants, statusSensors, statusSensorPlacement]

        if (arr.some(s => s === libCon.LOADING))
            setStatus(libCon.LOADING)
        else if (arr.some(s => s === libCon.ERROR))
            setStatus(libCon.ERROR)
        else if (arr.every(s => s === libCon.OK))
            setStatus(libCon.OK)


    }, [statusParticipants, statusSensors, statusSensorPlacement])



    return ([status, participants])
}



export const RefActiveParticipantsWithAllPlacements = () => {

    // Participants
    const allParticipants = RefStoredValue(libCon.DATASET_PARTICIPANTS)
    const statusParticipants = RefStoredValue(libCon.STATUS_DATASET_PARTICIPANTS)

    // Houses
    const houses = RefStoredValue(libCon.DATASET_HOUSES)
    const statusHouses = RefStoredValue(libCon.STATUS_DATASET_HOUSES)

    // Emails
    const emails = RefStoredValue(libCon.DATASET_EMAILS)
    const statusEmails = RefStoredValue(libCon.STATUS_DATASET_EMAILS)

    // Sensors
    const sensors = RefStoredValue(libCon.DATASET_SENSORS)
    const statusSensors = RefStoredValue(libCon.STATUS_DATASET_SENSORS)

    // Sensor Placement
    const sensorPlacements = RefStoredValue(libCon.DATASET_SENSOR_HOUSE_PLACEMENT)
    const statusSensorPlacement = RefStoredValue(libCon.STATUS_DATASET_SENSOR_HOUSE_PLACEMENT)

    // Wearables
    const wearables = RefStoredValue(libCon.DATASET_WEARABLES)
    const statusWearables = RefStoredValue(libCon.STATUS_DATASET_WEARABLES)

    // Wearable Placement
    const wearablesPlacements = RefStoredValue(libCon.DATASET_WEARABLE_PLACEMENT)
    const statusWearablesPlacement = RefStoredValue(libCon.STATUS_DATASET_WEARABLE_PLACEMENT)

    // Phones
    const phones = RefStoredValue(libCon.DATASET_PHONES)
    const statusPhones = RefStoredValue(libCon.STATUS_DATASET_PHONES)

    // Phone Placement
    const phonePlacements = RefStoredValue(libCon.DATASET_PHONE_PLACEMENT)
    const statusPhonePlacement = RefStoredValue(libCon.STATUS_DATASET_PHONE_PLACEMENT)

    // Received Sensor Files
    const receivedSensorFiles = RefStoredValue(libCon.DATASET_RECEIVED_SENSOR_FILES)
    const statusReceivedSensorFiles = RefStoredValue(libCon.STATUS_DATASET_RECEIVED_SENSOR_FILES)

    // Received Sensor Files
    const issues = RefStoredValue(libCon.DATASET_ISSUES)
    const statusIssues = RefStoredValue(libCon.STATUS_DATASET_ISSUES)

    const [participants, setParticipants] = useState(() => null)
    const [status, setStatus] = useState(() => libCon.STATUS_NOT_STARTED)


    useEffect(() => {

        let arr = [allParticipants,
            houses,
            emails,
            sensors,
            sensorPlacements,
            wearables,
            wearablesPlacements,
            phones,
            phonePlacements,
            receivedSensorFiles,
            issues]


        if (arr.every(s => !isNullOrUndefined(s))) {
            let newParticipants = dm_fun.filterOnlySEWA({ allParticipants: structuredClone(allParticipants) })

            newParticipants = dm_fun.filterOnlyActive({ participants: newParticipants })
            newParticipants = dm_fun.attachHousesToParticipants({ participants: newParticipants, houses: houses })
            newParticipants = dm_fun.attachIssuesToParticipant({ participants: newParticipants, issues: issues })
            newParticipants = dm_fun.attachSensorPlacementToParticipants({ participants: newParticipants, sensors: structuredClone(sensors), sensorPlacements: structuredClone(sensorPlacements) })
            newParticipants = dm_fun.attachReceivedSensorFiles({ participants: newParticipants, receivedSensorFiles: structuredClone(receivedSensorFiles) })
            newParticipants = dm_fun.attachPhonePlacement({ participants: newParticipants, phones: phones, phonePlacement: phonePlacements })
            newParticipants = dm_fun.attachWearablePlacement({ participants: newParticipants, wearables: wearables, wearablePlacement: wearablesPlacements, emails: emails })
            newParticipants = dm_fun.computeDaysUntilExpiry({ participants: newParticipants })
            newParticipants = dm_fun.computeDaysSinceLastUpload({ participants: newParticipants })
            newParticipants = dm_fun.computeDeviceCollectionStatus({ participants: newParticipants })



            setParticipants(newParticipants)

        }


    }, [allParticipants, houses, emails, sensors, sensorPlacements, wearables, wearablesPlacements, phones, phonePlacements, receivedSensorFiles, issues])


    useEffect(() => {

        let arr = [
            statusParticipants,
            statusHouses,
            statusEmails,
            statusSensors,
            statusSensorPlacement,
            statusWearables,
            statusWearablesPlacement,
            statusPhones,
            statusPhonePlacement,
            statusReceivedSensorFiles,
            statusIssues,
        ]

        if (arr.some(s => s === libCon.LOADING))
            setStatus(libCon.LOADING)
        else if (arr.some(s => s === libCon.ERROR))
            setStatus(libCon.ERROR)
        else if (arr.every(s => s === libCon.OK))
            setStatus(libCon.OK)


    }, [statusParticipants, statusSensors, statusHouses, statusEmails, statusSensorPlacement, statusWearables, statusWearablesPlacement, statusPhones, statusPhonePlacement, statusReceivedSensorFiles, statusIssues])



    return ([status, participants])
}



export const RefAllParticipantsWithPlacement = () => {

    // Participants
    const allParticipants = RefStoredValue(libCon.DATASET_PARTICIPANTS)
    const statusParticipants = RefStoredValue(libCon.STATUS_DATASET_PARTICIPANTS)

    // Paticipant Placements
    const allParticipantPlacements = RefStoredValue(libCon.DATASET_PARTICIPANT_PLACEMENT)
    const statusParticipantPlacements = RefStoredValue(libCon.STATUS_DATASET_PARTICIPANT_PLACEMENT)

    const [participants, setParticipants] = useState(() => null)
    const [status, setStatus] = useState(() => libCon.STATUS_NOT_STARTED)


    useEffect(() => {

        if (allParticipants !== null &&
            allParticipantPlacements !== null) {

            let newParticipants = dm_fun.filterOnlySEWA({ allParticipants: structuredClone(allParticipants) })

            newParticipants = dm_fun.attachParticipantPlacementsToParticipant({ participants: newParticipants, participantPlacements: allParticipantPlacements })

            setParticipants(newParticipants)

        }
        else
            setParticipants(null)

    }, [allParticipants, allParticipantPlacements])


    useEffect(() => {

        let arr = [statusParticipantPlacements, statusParticipants]

        if (arr.some(s => s === libCon.LOADING))
            setStatus(libCon.LOADING)
        else if (arr.some(s => s === libCon.ERROR))
            setStatus(libCon.ERROR)
        else if (arr.every(s => s === libCon.OK))
            setStatus(libCon.OK)



    }, [statusParticipants, statusParticipantPlacements])



    return ([status, participants])
}




export const RefAllSensorsWithPlacement = () => {

    // Sensors
    const allSensors = RefStoredValue(libCon.DATASET_SENSORS)
    const statusSensors = RefStoredValue(libCon.STATUS_DATASET_SENSORS)

    // Sensor Placements
    const allSensorPlacements = RefStoredValue(libCon.DATASET_SENSOR_HOUSE_PLACEMENT)
    const statusSensorPlacements = RefStoredValue(libCon.STATUS_DATASET_SENSOR_HOUSE_PLACEMENT)

    // Participants
    const allParticipants = RefStoredValue(libCon.DATASET_PARTICIPANTS)
    const statusParticipants = RefStoredValue(libCon.STATUS_DATASET_PARTICIPANTS)

    const [sensors, setSensors] = useState(() => null)
    const [status, setStatus] = useState(() => libCon.STATUS_NOT_STARTED)


    useEffect(() => {

        if (!isNullOrUndefined(allSensors) &&
            !isNullOrUndefined(allSensorPlacements) &&
            !isNullOrUndefined(allParticipants)) {

            let newSensors = dm_fun.attachSensorPlacementToSensors({ sensors: structuredClone(allSensors), sensorPlacements: structuredClone(allSensorPlacements) })

            newSensors = dm_fun.filterNotDevelopment({ allDevices: newSensors, allParticipants: allParticipants })



            setSensors(newSensors)

        }
        else
            setSensors(null)

    }, [allSensors, allSensorPlacements, allParticipants])


    useEffect(() => {

        let arr = [statusSensors, statusSensorPlacements, statusParticipants]

        if (arr.some(s => s === libCon.LOADING))
            setStatus(libCon.LOADING)
        else if (arr.some(s => s === libCon.ERROR))
            setStatus(libCon.ERROR)
        else if (arr.every(s => s === libCon.OK))
            setStatus(libCon.OK)


    }, [statusSensors, statusSensorPlacements, statusParticipants])



    return ([status, sensors])
}
