import { utils, writeFile, read } from 'xlsx';

export const exportToExcel = (data, fileName = 'Data.xlsx', columnOrder = []) => {
  // If columnOrder is provided, reorder the data accordingly
  if (columnOrder.length > 0) {
    data = data.map(row => {
      const orderedRow = {};
      columnOrder.forEach(col => {
        orderedRow[col] = row[col];
      });
      return orderedRow;
    });
  }

  const worksheet = utils.json_to_sheet(data);

  // Adjust column widths to fit content
  const columnWidths = data.reduce((widths, row) => {
    Object.keys(row).forEach((key, index) => {
      const valueLength = row[key] ? row[key].toString().length : 10;
      widths[index] = Math.max(widths[index] || 10, valueLength);
    });
    return widths;
  }, []);

  worksheet['!cols'] = columnWidths.map(width => ({ wch: width + 2 }));

  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  writeFile(workbook, fileName);
};



export function parseExcelFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // When the file is read successfully
    reader.onload = (event) => {
      try {
        const arrayBuffer = event.target.result;

        // Parse the Excel file
        const workbook = read(arrayBuffer, { type: "array", raw: false, cellDates: true });

        // Get the first sheet name
        const sheetName = workbook.SheetNames[0];

        // Get the sheet data
        const sheet = workbook.Sheets[sheetName];

        // Convert sheet to JSON
        const jsonData = utils.sheet_to_json(sheet);

        resolve(jsonData); // Return the array of objects
      } catch (error) {
        reject(error); // Handle errors
      }
    };

    // When there's an error reading the file
    reader.onerror = (error) => {
      reject(error);
    };

    // Read the file as an ArrayBuffer
    reader.readAsArrayBuffer(file);
  });
}